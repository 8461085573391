import React from "react";

import PropTypes from "prop-types";
import {injectIntl} from "gatsby-plugin-intl/index";
import { SpinnerContainer, SpinnerOverlay } from "./spinner.styles";

const Spinner = ({height}) => (
  <SpinnerOverlay height={height}>
    <SpinnerContainer />
  </SpinnerOverlay>
);

Spinner.propTypes = {
  height: PropTypes.number.isRequired,
}

export default injectIntl(Spinner);
