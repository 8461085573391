import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import ResetComponent from '../components/forgot-password/index_reset';
import DefaultLanguage from '../hoc/default-language';
import Layout from '../components/ui/layout';

const ForgotPassword = ({ location, pageContext }) => (
  <DefaultLanguage location={location}>
    <Layout location={location} language={pageContext.language} page="forgot-password">
      <ResetComponent />
    </Layout>
  </DefaultLanguage>
);

ForgotPassword.propTypes = {
  location: PropTypes.objectOf.isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.array).isRequired,
    touched: PropTypes.bool
  }).isRequired
};

export default injectIntl(ForgotPassword);
