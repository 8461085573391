import React from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl/index';
import { Button, TokenExpired } from './styles';

const TokenExpiredUI = ({ loading = false, success = false }) => {
  if (loading || success) {
    return null;
  }

  return (
    <TokenExpired>
      <p>
        <FormattedMessage id="apply.token" />
      </p>
      <Button
        style={{ marginTop: '25px' }}
        type="button"
        // onClick={() => navigate("/download")}
        onClick={() => navigate('/')}>
        <FormattedMessage id="apply.forgotPasswordHome" />
      </Button>
    </TokenExpired>
  );
};

TokenExpiredUI.propTypes = {
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired
};

export default injectIntl(TokenExpiredUI);
