import React from "react";
import {Rights} from "./styles";


const RightsFooter = () => (
  <Rights>
    <p>
                © Numu {new Date().getFullYear()}{" "}
                - All Rights Reserved
    </p>
  </Rights>
);

export default RightsFooter;
