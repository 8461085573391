import React from "react";
import {injectIntl, FormattedMessage} from "gatsby-plugin-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import {Flex, Row, SuccessButton, SuccessButtonContainer, Text} from "./styles";

const SuccessDialog = ({showAlert, onConfirm, onClose}) => {

  if (!showAlert) {
    return null
  }

  return <SweetAlert
    type='custom'
    showConfirm={false}
    onConfirm={onConfirm}
    timeout={3000}
    customClass='swal-title'
    title={<FormattedMessage id="apply.heading"/>}
  >
    <Flex>
      <Row className='text-row' style={{flexFlow: 'column'}}>
        <Text style={{margin: '0 1rem'}}>
          <FormattedMessage id="apply.updatedPassword"/>
        </Text>
      </Row>
      <Row className='buttons-row'>
        <SuccessButtonContainer>
          <SuccessButton onClick={onClose} primary>
            <FormattedMessage id="apply.forgotPasswordHome"/>
          </SuccessButton>
        </SuccessButtonContainer>
      </Row>
    </Flex>
  </SweetAlert>
}

SuccessDialog.propTypes = {
  showAlert: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default injectIntl(SuccessDialog);
