import styled, {css} from "styled-components";

const gradientStyle = css`
  background: linear-gradient(90deg, #ff6985, #c14380, #5d779c, #4d9fc0);
  color: #fff;
`;

const simpleStyle = css`
  background-color: #ebebeb;
  color: #666666;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 24px;
  color: #939191;
  letter-spacing: 1px;
  line-height: 1.422;
`;

const getButtonStyles = (props) => {
  if (props.gradient) {
    return gradientStyle;
  }
  return simpleStyle;
};

export const SuccessButtonContainer= styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

export const SuccessButton= styled.button`
  width: auto;
  min-width: ${(props) => props.width || '170'}px;
  height: ${(props) => props.height || '35'}px;
  background-color: ${(props) => (props.primary ? '#3f5c83' : '#fff')};
  color: ${(props) => (props.primary ? '#FEFEFF' : '#3f5c83')};
  border: 2px solid
    ${(props) => (props.border || props.primary ? '#3f5c83' : '#F9F7F9')};
  box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  text-align: center;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-family: Montserrat Medium;
  @media (max-width: 425px) {
    width: 60px;
  }
`

export const Button = styled.button`
  height: 50px;
  width: 340px;
  font-family: 'Montserrat Regular';
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  outline: none;

  ${getButtonStyles}

  /* small device */
  @media (min-width: 320px) and (max-width: 399px) {
    height: 43px;
    width: 266px;
    font-size: 16px;
  }

  /* small device */
  @media (min-width: 400px) and (max-width: 499px) {
    height: 46px;
    font-size: 18px;
  }

  @media (min-width: 400px) and (max-width: 499px) {
    width: 300px;
  }
  
  margin-top: 10px;
  background-color: #6d8cad !important;
  border-radius: 5px !important;
  color: #ffffff;
  font-size: 20px !important;
  width: 100%;
  text-align: center;
  &:hover {
    box-shadow: none;
    color: #ffffff;
    outline: none;
    background-color: #7f9dc6;
    -webkit-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.25);
  }
  &:focus {
    color: #ffffff;
  }
`;

export const TokenExpired = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 26px; */

  @media (max-width: 500px) {
    width: 270px;
  }

  p {
    color: #999999;
    font-family: 'Montserrat Regular';
    font-size: 16px;
    text-align: center;
  }

  a {
    margin-top: 20px;
    display: block;
    font-family: 'Montserrat Regular';
    border-bottom: 1px solid #999999;
    color: #999999;
    font-size: 16px;
    :hover {
      text-decoration: none;
    }
  }
`;

export const Rights = styled.div`
  text-align: center;
  padding-top: 35px;

  p {
    color: #333333;
    font-family: 'Montserrat Regular';
    font-size: 14px;
  }

  @media (min-width: 900px) {
    p {
      font-size: 18px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background-color: #fafafa;
  padding-top: 45px;
  padding-bottom: 15px;

  @media (min-width: 1500px) and (max-width: 2400px) {
  }

  @media (min-width: 2401px) {
    zoom: 1.6;
  }
`;

export const FormContainer = styled.div`
  padding: 35px 0;
  width: 614px;
  /* margin-right: auto; */
  background-color: #ffffff;
  box-shadow: 3px 4px 5px 2px rgb(0 0 0 / 30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;

  /* small device */
  @media (min-width: 320px) and (max-width: 399px) {
    width: 300px;
  }

  @media (min-width: 400px) and (max-width: 499px) {
    width: 370px;
  }

  @media (min-width: 500px) and (max-width: 638px) {
    width: 473px;
  }

  /* Title */
  .title {
    margin: 10px 0;

    /* small device */
    @media (max-width: 499px) {
      margin-top: 10px;
    }

    p {
      color: #29435a;
      font-family: 'Montserrat Bold';
      font-size: 28px;
      /* font-weight: bold; */
      text-align: center;
    }

    /* small device */
    @media (max-width: 399px) {
      p {
        font-size: 18px;
        margin-top: 15px;
      }
    }

    /* small device */
    @media (min-width: 400px) and (max-width: 499px) {
      p {
        font-size: 22px;
        margin: 0;
      }
    }
    /* margin: 10px 0;

    p {
      color: #29435a;
      font-family: "Louis George Cafe Bold";
      font-size: 28px;
      text-align: center;
    }

    @media (max-width: 399px) {
      p {
        font-size: 18px;
        margin-top: 15px;
      }
    }

    @media (min-width: 400px) and (max-width: 499px) {
      p {
        font-size: 22px;
        margin: 0;
      }
    } */
  }

  /* Input Fields */
  .input-fields {
    /* width: 100%; */
    margin-top: 25px;
    position: relative;

    /* small device */
    @media (min-width: 400px) and (max-width: 499px) {
      margin-top: 15px;
    }

    /* small device */
    @media (min-width: 320px) and (max-width: 399px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .help-block {
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 10px;
      color: #a94442;
    }

    .show-password-icon {
      cursor: pointer;
      position: absolute;
      top: 36%;
      right: 4px;
      transform: translate(-50%, -50%);
      img {
        max-width: 100%;
      }

      @media (min-width: 320px) and (max-width: 399px) {
        right: 14px;
      }
    }

    .input-field {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 340px;

      /* small device */
      @media (min-width: 320px) and (max-width: 399px) {
        width: 270px;
        margin-left: auto;
        margin-right: auto;
      }

      @media (min-width: 400px) and (max-width: 499px) {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-50%, -50%);
        img {
          max-width: 100%;
        }
      }

      .icon-2 {
        position: absolute;
        top: 50%;
        cursor: pointer;
        right: 4px;
        transform: translate(-50%, -50%);
        img {
          max-width: 100%;
        }
      }

      .input {
        height: 50px;
        width: 340px;
        border: 1px solid #b3b3b3;
        font-family: 'Montserrat Medium';
        color: #4d4d4d !important;
        font-size: 14px;
        border-radius: 5px;
        padding-left: 40px;
        padding-right: 10px;
        outline: none;
        box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05);
        @media (max-width: 499px) {
            margin-bottom: 5px;
        }
        &.has-error {
          border-color: #a94442;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        }

        ::-webkit-input-placeholder {
          font-family: 'Louis George Cafe';
          font-size: 18px;
          color: #999999;
        }

        ::-moz-placeholder {
          font-family: 'Louis George Cafe';
          font-size: 18px;
          color: #999999;
        }

        :-ms-input-placeholder {
          font-family: 'Louis George Cafe';
          font-size: 18px;
          color: #999999;
        }

        :-moz-placeholder {
          font-family: 'Louis George Cafe';
          font-size: 18px;
          color: #999999;
        }

        @media (min-width: 320px) and (max-width: 399px) {
          width: 270px;
          height: 45px;
        }

        @media (min-width: 400px) and (max-width: 499px) {
          width: 300px;
        }
      }
    }

    /* Forgot password */
    .forgot-password {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;

      .link {
        display: inline-block;
        font-family: 'Montserrat Regular';
        border-bottom: 1px solid #999999;
        color: #999999;
        font-size: 16px;
      }
    }
  }
`;

export const ForgetPasswordContainer = styled(FormContainer)`
  /* height: 533px; */
  width: 614px;

  /* small device */
  @media (min-width: 320px) and (max-width: 399px) {
    /* height: 375px; */
    width: 300px;
  }

  @media (min-width: 400px) and (max-width: 499px) {
    /* height: 375px; */
    width: 370px;
  }

  @media (min-width: 500px) and (max-width: 638px) {
    /* height: 500px; */
    width: 473px;
  }
`;

export const Logo = styled.div`
  margin: 10px 0 25px 0;
  width: 260px;
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
  }

  /* small device */
  @media (min-width: 320px) and (max-width: 399px) {
    /* height: 90px; */
    margin: 5px 0 0 0;
    width: 160px;
  }

  /* small device */
  @media (min-width: 400px) and (max-width: 499px) {
    /* height: 130px; */
    margin: 10px 0 15px 0;
    width: 180px;
  }
`;


export const ErrorAlert = styled.div`
  font-family: 'Montserrat Regular';
  color: rgb(169, 68, 66) !important;
  font-size: 14px;
`;
