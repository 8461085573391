import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { Field, Form, Formik } from 'formik';
import Axios from 'axios';
import { StaticImage } from 'gatsby-plugin-image';
import { isEmpty } from 'lodash';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Container, FormContainer, Logo } from './styles';
import PasswordField from './password-field';
import ButtonLoader from './btn-loader';
import AccountButton from './account-btn';
import Spinner from '../spinner/spinner';
import SuccessDialog from './success-dialog';
import { initialValues, Schema } from './const';
import TokenExpiredUI from './token-expired';
import RightsFooter from './rights-footer';

const NODE_API = process.env.GATSBY_NODE_API;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loading: true,
      success: false,
      showAlert: false,
      inputType: {
        password: 'password',
        confirmPassword: 'password'
      }
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const token = window.location.search.slice(1).split('&')[0];

    try {
      const response = await Axios.post(`${NODE_API}/app/verify-reset-token`, {
        token
      });
      if (response) this.setState({ loading: false, success: true });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error ->', err.response || err);
      this.setState({ loading: false, success: false });
    }
  }

  onSubmit = async (values) => {
    this.setState({
      isLoading: true
    });
    try {
      const token = window.location.search.slice(1).split('&')[0];
      const { password } = values;
      await Axios.post(`${NODE_API}/app/password-reset`, {
        token,
        password
      });
      this.setState({ showAlert: true });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('forgot password error ->', err.response || err);
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  onConfirm = () => {};

  onClose = () => {
    this.setState({ showAlert: false }, () => navigate('/'));
  };

  changeInputType = (name) => {
    const { inputType } = this.state;
    this.setState((prevState) => ({
      inputType: {
        ...prevState.inputType,
        [name]: inputType[name] === 'password' ? 'text' : 'password'
      }
    }));
  };

  render() {
    const {
      isLoading,
      inputType: { password, confirmPassword } = {},
      loading,
      success,
      showAlert
    } = this.state;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={this.onSubmit}
        render={({ values, errors }) => {
          const isError = !isEmpty(errors);

          return (
            <Form>
              <Container>
                <FormContainer>
                  <Logo>
                    <StaticImage src="../../images/login/numu-logo.png" alt="logo-image" />
                  </Logo>
                  <div className="title">
                    <p>
                      <FormattedMessage id="apply.heading" />
                    </p>
                  </div>

                  {loading && <Spinner height="20vh" />}

                  {!loading && success && (
                    <div className="input-fields">
                      <FormattedMessage id="apply.enterNewPassword">
                        {(msg) => (
                          <Field
                            type={password}
                            showPassword={this.changeInputType}
                            name="password"
                            placeholder={msg}
                            component={PasswordField}
                          />
                        )}
                      </FormattedMessage>
                      <br />
                      <FormattedMessage id="apply.enterSamePassword">
                        {(msg) => (
                          <Field
                            type={confirmPassword}
                            showPassword={this.changeInputType}
                            name="confirmPassword"
                            placeholder={msg}
                            component={PasswordField}
                          />
                        )}
                      </FormattedMessage>
                      <div>
                        <br />
                        <AccountButton
                          isDisabled={isLoading || values.password === '' || isError}
                          text={
                            isLoading ? <ButtonLoader /> : <FormattedMessage id="apply.submit" />
                          }
                        />
                      </div>
                    </div>
                  )}

                  <TokenExpiredUI loading={loading} success={success} />
                </FormContainer>
                <RightsFooter />
              </Container>

              {showAlert && (
                <SuccessDialog showAlert onConfirm={this.onConfirm} onClose={this.onClose} />
              )}
            </Form>
          );
        }}
      />
    );
  }
}

export default injectIntl(ResetPassword);
