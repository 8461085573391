import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "gatsby-plugin-intl";
import { Button } from './styles';

const AccountButton = ({ text, isDisabled }) => (
  <Button type="submit" disabled={isDisabled} className="btn account-button">
    {text}
  </Button>
);

AccountButton.propTypes = {
  text: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default injectIntl(AccountButton);
