import React from "react";

function ButtonLoader() {
  return (
    <i
      className="fa fa-circle-o-notch fa-spin"
      style={{
        fontSize: "18px",
        marginRight: "2em",
        marginLeft: "2em"
      }}
    />
  );
}

export default ButtonLoader;
