import * as Yup from "yup";

export const initialValues = {
  password: "",
  confirmPassword: "",
};


export const Schema = Yup.object().shape({
  password: Yup.string().required(
    "apply.required"
  ),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "apply.mustMatch"
    )
    .required("apply.required"),
});

