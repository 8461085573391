import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {StaticImage} from "gatsby-plugin-image";
import {FormattedMessage} from "gatsby-plugin-intl";
import {ErrorAlert} from "./styles";

const PasswordField = ({
  field: {name, value, onChange, onBlur},
  form: {errors, touched},
  showPassword,
  onIconClick,
  type,
  placeholder
}) => {
  let errorText = ''
  const error = touched[name] && errors[name];
  if (error) {
    errorText = errors[name];
  }


  return (
    <>
      <div className="input-field">
        <input
          name={name}
          value={value || ''}
          className={classnames('input', touched[name] && errors[name] ? 'has-error' : '')}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          placeholder={placeholder}
        />
        <div className="icon">
          <StaticImage src="../../images/login/lock-icon.png" alt="" onClick={onIconClick}/>
        </div>
        {showPassword && (
          <div className="icon-2" onClick={(e)=>showPassword(name, e)} role="presentation">
            <StaticImage src="../../images/login/show-password.png" alt="logo-image"/>
          </div>
        )}
      </div>
      {error && <ErrorAlert className="text-danger">
        <FormattedMessage id={errorText}/>
      </ErrorAlert>}
    </>
  );
};

PasswordField.propTypes = {
  field: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    })
  ).isRequired,
  form: PropTypes.objectOf(
    PropTypes.shape({
      errors: PropTypes.arrayOf(PropTypes.array).isRequired,
      touched: PropTypes.bool.isRequired,
    })
  ).isRequired,
  showPassword: PropTypes.bool.isRequired,
  onIconClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  placeholder:  PropTypes.string.isRequired
};
export default PasswordField;
